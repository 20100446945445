import React from "react";
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import { Link } from "gatsby-plugin-react-i18next";
const DocExample = ({
  title,
  downloadButton,
  downloadButton2,
  seeFilledFormButton,
  downloadIcon,
  href,
  href2,
}) => {
  return (
    <Flex
      flexDirection="column"
      bg="green.200"
      borderRadius="24px"
      p="2rem"
      w="full"
      h="300px"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Image
        objectFit="cover"
        src={downloadIcon}
        alt="FSM News"
        mb={5}
        w="60px"
      />
      <Box as="span" fontWeight="500" mb="12" fontSize="2xl" color="white">
        {title}
      </Box>
      <Flex flexDirection="column" alignItems="flex-start">
        <Button
          as={Link}
          to={href}
          target="_blank"
          download
          mb="4"
          textDecoration="underline"
          variant="text"
          color="white"
          size="sm"
        >
          {downloadButton}
        </Button>
        <Button
          as={Link}
          to={href2}
          target="_blank"
          download
          textDecoration="underline"
          variant="text"
          color="white"
          size="sm"
        >
          {downloadButton2}
        </Button>
      </Flex>
    </Flex>
  );
};
export default DocExample;
