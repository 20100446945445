import * as React from "react";
import {
  Container,
  Heading,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import DocExample from "../../components/docexample";
import usePrismicDocuments from "../../hooks/usePrismicDocuments";
import usePrismicDocumentExamples from "../../hooks/usePrismicDocumentExamples";
const StandartDocumentExample = () => {
  const { language } = useTranslation();
  const data = usePrismicDocuments();
  return (
    <Main>
      <Container maxW="7xl" px="5" mb="9.5rem">
        <Seo
          title= {data.title}
          description= {data.title}
          link={`https://www.fsm.am/${language}/Փաստաթղթերի Տիպային Օրինակներ`}
        />
        <SmallNav>
            {data.title}
        </SmallNav>
        <Heading as="h1" fontSize="2xl" mt={{base: '3', sm: "5"}} mb={{base: '10', sm: "3.375rem"}}>
          {data.title}
        </Heading>
        <Box w="full" mb="6.25rem">
          <SimpleGrid
            w="full"
            columns={{
              base: 1,
              md: 2,
              lg: 3,
            }}
            spacing="6"
          >
            {data.documents?.map((document, idx) => (
                <DocExample
                    key={`doc-${document.documentName}-${idx}`}
                    title={document.documentName}
                    downloadButton={document.downloadButtonText}
                    seeFilledFormButton={document.seeFilledFormButton}
                    downloadIcon={document.downloadIcon}
                />
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </Main>
  );
};

export default StandartDocumentExample;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
