import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicDocuments = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicDocuments {
      allPrismicUsefulinformation(filter: {tags: {in: "useful_information"}}) {
        nodes {
          lang
          data {
            documenttitle {
                text
            }
            documents {
              documentname
              seefilledformbutton
              downloadbuttontext
              downloadicon {
                url
              }
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicUsefulinformation.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        title: response.documenttitle.text,
        documents: response.documents.map(document => ({
            documentName: document.documentname,
            downloadButtonText: document.downloadbuttontext,
            downloadIcon: document.downloadicon.url,
            seeFilledFormButton: document.seefilledformbutton,
        })),
    }
}

export default usePrismicDocuments;